import {set, toggle } from '@/utils/vuex'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        drawer: false,
        domain: '',
        domainToPurchase : '',
        domainCurrency : '',
        domainPrice : 0,
        zone: '',
        zoneid: Number,
        session: '',
        islogged: false
    },
    mutations: {
        setDrawer: set('drawer'),
        toggleDrawer: toggle('drawer'),
        SET_drawerDashboard(state, payload) {
            state.drawerDashboard = payload
        },
        //setter
        searchDomain: function(state, data) {
            state.domain = data;
        },
        searchZone: function(state, data) {
            state.zone = data;
        },
        searchZoneId: function(state, data) {
            state.zoneid = data;
        },
        sessionToken: function(state, data) {
            state.session = data;
        },
        logged: function(state, data) {
            state.islogged = data;
        },
        domainCurrency: function(state, data) {
            state.domainCurrency = data;
        },
        domainPrice: function(state, data) {
            state.domainPrice = data;
        },
        domainToPurchase : function(state, data) {
            state.domainToPurchase = data;
        }
    },
    actions: {
        toggleDrawer({ commit }) {
            commit('toggleDrawer');
        },
        searchDomain({ commit }) {
            commit('searchDomain');
        },
        sessionToken({ commit }) {
            commit('sessionToken');
        },
        logged({ commit }) {
            commit('logged');
        },
        domainCurrency({ commit }) {
            commit('domainCurrency');
        },
        domainPrice({ commit }) {
            commit('domainPrice');
        },
        domainToPurchase({ commit }) {
            commit('domainToPurchase');
        }
    },
    getters: {
        active: (state) => {
            return state.drawer;
        },
        getdomain: (state) => {
            return state.domain;
        },
        getzone: (state) => {
            return state.zone;
        },
        getzoneid: (state) => {
            return state.zoneid;
        },
        getSesionToken: (state) => {
            return state.sesion;
        },
        isLogged: (state) => {
          if(state.islogged === false)
          {
            if(!window.localStorage.getItem(window.atob( 'usr' )))
            {
              state.islogged = false;
            }
            else
            state.islogged = true;
          }
          return state.islogged;
        },
        getdomainCurrency : (state) => {
            return state.domainCurrency;
        },
        getdomainPrice : (state) => {
            return state.domainPrice;
        },
        getdomainToPurchase : (state) => {
            return state.domainToPurchase;
        }
    }
})