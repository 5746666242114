const PROD = 'https://apiecommercenic.uni.edu.ni/api/v1/';
//const DEV = 'https://apiecommercenic.uni.edu.ni/api/v1/';
//const DEV = 'https://apiecommercenic-dev.uni.edu.ni/api/v1/';
// const DEV = 'https://localhost:5001/api/v1/';
const URL_ENDPOINT = PROD;

export const apiecommerce = {
    WHO_IS: URL_ENDPOINT + 'dominios/whois',
    TIPO_IDENTIFICACION: URL_ENDPOINT + 'catalogos/IdentificacionTipo',
    PAIS: URL_ENDPOINT + 'catalogos/Pais',
    REGISTRAR_USUARIO: URL_ENDPOINT + 'usuario/Natural',
    REGISTRAR_USUARIO_JURIDICO: URL_ENDPOINT + 'usuario/Juridico',
    CLIENTE_ACTIVIDAD_NATURAL: URL_ENDPOINT + 'catalogos/ClienteActividad/1',
    CLIENTE_ACTIVIDAD_JURIDICO: URL_ENDPOINT + 'catalogos/ClienteActividad/2',
    CLIENTE_JURIDICO_CLASIFICACION: URL_ENDPOINT + 'catalogos/ClasificacionJuridico',
    INICIAR_SESION: URL_ENDPOINT + 'usuario/Autenticacion',
    DISPONIBILIDAD_DOMINIO: URL_ENDPOINT + 'dominios/disponibilidadDominio',
    ZONAS: URL_ENDPOINT + 'zonas/Ecommerce',
    TRACK: URL_ENDPOINT + 'pagos/track',
    PAGOS: URL_ENDPOINT + 'pagos',
    CLIENTEDETALLEDOM: URL_ENDPOINT + 'dominios/detalle',
    TERMINOS_CONDICIONES: URL_ENDPOINT + 'politicas',
    REENVIARCORREO: URL_ENDPOINT + 'pagos/reenviarcorreo',
    DOMINIO_HISTORICO: URL_ENDPOINT + 'dominios/historico',
    TARJETA: URL_ENDPOINT + 'tarjeta',
    INFO_CUENTA: URL_ENDPOINT + 'clientes',
    PUT_CLIENTES_NATURAL: URL_ENDPOINT + 'clientes/Natural',
    PUT_CLIENTES_JURIDICO: URL_ENDPOINT + 'clientes/Juridico',
    CAMBIAR_CONTRASENA: URL_ENDPOINT + 'usuario/CambioPassword',
    RECUPERAR_CONTRASENA: URL_ENDPOINT + 'usuario/RecuperarPass',
    CONTACTO_TIPO: URL_ENDPOINT + 'catalogos/ContactoTipo',
    DOMINIOS: URL_ENDPOINT + 'dominios',
    SOLICITAR_DOMINIO: URL_ENDPOINT + 'dominios/solicitar',
    SOLICITUD_CAMBIO_DNS: URL_ENDPOINT + 'dominios/SolicitudCambioDNS',
    SOLICITUD_CAMBIO_DOMINIO: URL_ENDPOINT + 'dominios/SolicitudCambioDominio',
    SOLICITUD_APROBACION_DOMINIO: URL_ENDPOINT + 'solicitudaprobaciondominio/dominiosaprobados',
    SOLICITUD_APROBACION: URL_ENDPOINT + 'solicitudaprobaciondominio'
};

apiecommerce.install = function(Vue) {
    Vue.prototype.$keys = (key) => {
        return apiecommerce[key];
    }
}

export default apiecommerce;