<template>
  <v-app>
    <template name v-if="$route.name && !$route.name.includes('admin-')">
      <Menu />
      <Drawer />
      <Jumbotron />
    </template>
    <Vista />
    <template v-if="$route.name && !$route.name.includes('admin-')">
      <MenuFooter />
      <Footer />
    </template>
  </v-app>
</template>
<script>
export default {
  metaInfo: {
    titleTemplate: "%s Nic.Ni",
  },

  components: {
    Drawer: () => import("@/components/Drawer"),
    Footer: () => import("@/components/Footer"),
    MenuFooter: () => import("@/components/MenuFooter"),
    Menu: () => import("@/components/Menu"),
    Jumbotron: () => import("@/components/Jumbotron"),
    Vista: () => import("@/components/Vista"),
  },
};
</script>


